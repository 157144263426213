"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
var Page = /** @class */ (function () {
    function Page() {
        this.cid = "Page";
        this._isInitialized = false;
        this._isActivated = false;
        this.animationEvent = this.whichAnimationEvent();
        //console.log(`${this.cid}(): ${Object.prototype.toString.call(this)}`);
    }
    Object.defineProperty(Page.prototype, "isInitialized", {
        // get [Symbol.toStringTag](): string {
        //   return this.cid;
        // }
        get: function () {
            return this._isInitialized;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "isActivated", {
        get: function () {
            return this._isActivated;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Run processes that should be completed before activate()
     */
    Page.prototype.init = function () {
        var _this = this;
        return new Promise(function (resolve) {
            resolve((_this._isInitialized = true));
        });
    };
    /**
     * Run only after the DOM is ready to manipulate DOM elements
     */
    Page.prototype.activate = function () {
        return (this._isActivated = true);
    };
    /**
     * Remove listeners from DOM elements; deconstruct dynamic DOM elements
     */
    Page.prototype.deactivate = function () {
        return (this._isActivated = false);
    };
    /**
     * Helper for animated elements.  Functional, but needs review.  Could
     * possibly be eliminated give current browser capabilities.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Page.prototype.whichAnimationEvent = function () {
        var t;
        var el = document.createElement("fakeAnimationElement");
        var animations = {
            animation: "animationend",
            OAnimation: "oAnimationEnd",
            MozAnimation: "animationend",
            WebkitAnimation: "webkitAnimationEnd",
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var cssStyle = el.style;
        for (t in animations) {
            if (cssStyle[t] !== undefined) {
                return animations[t];
            }
        }
    };
    return Page;
}());
exports.Page = Page;
